export const NAVIGATION_KEYS = {
  PERFORMANCES: 'performances',
  ARTISTS: 'artists',
  ORGANIZATIONS: 'organizations',
  MANAGERS: 'managers',
  VENUES: 'venues',
  SEASONS: 'seasons',
  COMPETITIONS: 'competitions',
  CREATORS: 'creators',
  WORKS: 'works',
  WATCH_ONLINE: 'online',
};
