import React, { useMemo } from 'react';

import Modal from 'components/uiLibrary/Modal';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import { TextButton } from 'components/uiLibrary/LinkButton';
import Image from 'components/uiLibrary/Image';

import useTracking from 'components/Globals/Analytics';
import { COMPONENTS, SECTIONS, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import queries from 'containers/Globals/queries';

import { useQuery } from 'utils/react-query';
import useScrollBlocker from 'utils/hooks/useScrollBlocker';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import usePageContext from 'utils/hooks/usePageContext';
import { useTranslation } from 'src/i18n';

import { TP, BASE_PAGE_ROUTES } from 'constants/index';
import routes from 'constants/routes';
import { LP_PRO_TOOL_ITEM } from 'components/LandingPages/common/consts';

import LogoImage from 'public/images/logo.png';

import classes from './ProTools.module.scss';

const FEATURES = [
  {
    title: `${TP}.FN_CASTINGTOOL`,
    key: LP_PRO_TOOL_ITEM.CASTING_TOOL,
  },
  {
    title: `${TP}.LP_ARTIST_PROFILE_MANAGEMENT`,
    key: LP_PRO_TOOL_ITEM.AP_MANAGEMENT,
    path: routes.INTRO_ARTISTS,
  },
  {
    title: `${TP}.LP_COMPANY_PAGE_MANAGEMENT`,
    key: LP_PRO_TOOL_ITEM.COMPANY_PAGE_MANAGEMENT,
    path: routes.INTRO_AOS,
  },
  {
    title: `${TP}.LP_AGENCY_ROSTER_MANAGEMENT`,
    key: LP_PRO_TOOL_ITEM.AGENCY_ROSTER_MNGMNT,
    path: routes.INTRO_AGENTS,
  },
  {
    title: `${TP}.m_RENTALS`,
    key: LP_PRO_TOOL_ITEM.RENTALS,
    path: routes.INTRO_ALL,
  },
  {
    title: `${TP}.FN_CASTINGTOOL_OPERABASE_ARCHIVES`,
    key: LP_PRO_TOOL_ITEM.OB_ARCHIVES,
    path: routes.INTRO_ALL,
  },
  {
    title: `${TP}.LP_PERFORMANCE_EXPORT_ON_REQUEST`,
    key: LP_PRO_TOOL_ITEM.PERFORMANCE_EXPORT,
    path: routes.INTRO_ALL,
  },
  {
    title: `${TP}.LP_REQUEST_RED_MASK`,
    key: LP_PRO_TOOL_ITEM.REQUEST_RED_MASK,
    path: routes.INTRO_ALL,
  },
];

const trackingData = {
  section: SECTIONS.GLOBAL_HEADER,
  component: COMPONENTS.PRO_TOOLS,
};

const ProTools = ({ onClose }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isDesktop } = useDeviceTypeLayouts();
  const { navigate } = usePageContext();
  const { data: statsNumbers } = useQuery(queries.getHomeNumbers());
  const track = useTracking();

  const castingToolLinkProps = navigate.getLinkProps({
    baseRoute: BASE_PAGE_ROUTES.CASTING,
    onlyLinkProps: true,
  });

  const staticInfo = useMemo(() => {
    const staticInfoKeys = {
      genres: `${TP}.GENRES`,
      performances: `${TP}.m_PERF`,
      artists: `${TP}.m_ARTISTS`,
      organizations: `${TP}.FN_ORGANIZATIONS`,
      venues: `${TP}.m_VENUES`,
    };
    return Object.entries(staticInfoKeys).map(([key, title]) => ({
      count: statsNumbers?.data?.[key]?.total,
      title,
    }));
  }, [statsNumbers?.data]);

  useScrollBlocker(true);

  return (
    <Modal
      isOpen
      onClose={onClose}
      className={classes.drawer}
      allowMobileDrawer
      styles={{ modalContainer: classes.modalContainer }}
    >
      <div className={classes.proTools__header}>
        <div className={classes.proTools__headerTop}>
          {isDesktop ? (
            <span />
          ) : (
            <SpriteIcon
              icon="arrow_back"
              size="16"
              onClick={() => {
                track.click({
                  ...trackingData,
                  subComponent: SUB_COMPONENTS.BACK_CTA,
                });
                onClose();
              }}
            />
          )}
          <div className={classes.proTools__logoSection}>
            <Image
              src={LogoImage}
              alt="Operabase Home"
              lazy={false}
              className={classes.proTools__logoImage}
              disableCloudinary
              disableNextImage
            />
            <Typography size="10">{t(`${TP}.LP_SINCE`)}</Typography>
          </div>
          <SpriteIcon
            icon="close"
            size="16"
            onClick={() => {
              track.click({
                ...trackingData,
                subComponent: SUB_COMPONENTS.CLOSE_CTA,
              });
              onClose();
            }}
          />
        </div>
        <div className={classes.proTools__headerBottom}>
          <Typography size="10" color="secondary" className={classes.proTools__statsSection}>
            {t(`${TP}.DATABASE_ALL_PERFORMING_ARTS`)} :{' '}
            {staticInfo?.map(({ count, title }) => (
              <Typography key={title} className={classes.proTools__stats}>
                <Typography size="10" weight="medium">
                  {t(title)}
                </Typography>{' '}
                <Typography size="10" color="secondary" weight="medium">
                  {count}
                </Typography>
              </Typography>
            ))}
          </Typography>
        </div>
      </div>
      <div>
        <div className={classes.proTools__feature}>
          <Typography size="14" weight="medium">
            {t(`${TP}.FN_PRO_TOOL`)}
          </Typography>
        </div>
        {FEATURES.map(feature => (
          <TextButton
            key={feature.key}
            isLink
            disableUnderline
            styles={{ root: classes.proTools__feature }}
            {...(feature.key === LP_PRO_TOOL_ITEM.CASTING_TOOL
              ? castingToolLinkProps
              : {
                  href: `${feature.path}#${feature.key}`,
                })}
            onClick={onClose}
            trackingData={{
              ...trackingData,
              subComponent: SUB_COMPONENTS.NAVIGATE_CTA,
              meta: {
                id: feature.key,
              },
            }}
          >
            <Typography size="12" color="secondary">
              {t(feature.title)}
            </Typography>
            <SpriteIcon icon="chevron_right" size="14" />
          </TextButton>
        ))}
      </div>
    </Modal>
  );
};

export default ProTools;
