/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _pick from 'lodash-es/pick';
import debounce from 'lodash-es/debounce';
import { useTranslation } from 'src/i18n';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { CircularProgress, ClickAwayListener, Fade, Popper } from '@mui/material';
// TODO move above to uiLib components
import useNewTracking from 'components/Globals/Analytics';
import {
  CATEGORIES,
  COMPONENTS,
  GOOGLE_OLD_TRACKING_SERVICES,
  SUB_COMPONENTS,
} from 'components/Globals/Analytics/constants';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';
import Button from 'components/uiLibrary/Button';

import { useScrollBlocker } from 'utils/hooks/useScrollBlocker';
import {
  checkIfSearchQueryIsValid,
  getPlaceHolderText,
  getSectionLinkGenFn,
  pathnameToFilterType,
  section_label_map,
} from 'utils/search';
import { pushSearchHistoryItem, useSearchHistory } from 'utils/search/searchHistory';
import { TRACK_EVENTS, GLOBAL_SEARCH_EVENT_IDS } from 'utils/tracking';
import { useVisitsHistory } from 'utils/search/visitsHistory';
import usePageContext from 'utils/hooks/usePageContext';

import { TP } from 'constants/consts';
import { URL_STATIC_PATHS } from 'constants/index';
import classnames from 'src/classnames';

import { search_types, INDEX_PAGE_SEARCH_TABS_MAPPING } from 'constants/search';
import useInputSearchTracking from 'components/Globals/Analytics/hooks/useInputSearchTracking';
import styles from './HeaderSearch.module.scss';

const TypeaheadSection = dynamic(() => import('components/Globals/Layout/Header/HeaderSearch/TypeaheadSection'));
const PerformanceSearch = dynamic(() =>
  import(
    'components/Search/AdvancedSearch/AdvancedSearchContent/AdvancedSearchTabContent/tabsContent/PerformanceSearch'
  ),
);
const OperaOnlineSearch = dynamic(() =>
  import(
    'components/Search/AdvancedSearch/AdvancedSearchContent/AdvancedSearchTabContent/tabsContent/OperaOnlineSearch'
  ),
);

const ORGANISATION_SUB_TYPES_MAPPING = {
  [section_label_map.company]: URL_STATIC_PATHS.ORG_TYPE_COMPANY,
  [section_label_map.festival]: URL_STATIC_PATHS.ORG_TYPE_FESTIVAL,
  [section_label_map.venues]: URL_STATIC_PATHS.ORG_TYPE_VENUE,
};

const getTypeAndSubTypeForOrganisations = type => {
  if (ORGANISATION_SUB_TYPES_MAPPING[section_label_map[type]]) {
    return { type: 'organizations', subType: ORGANISATION_SUB_TYPES_MAPPING[section_label_map[type]] };
  }

  return { type };
};

const HeaderSearch = ({ forceDrawerOpen = false, autoFocus = false, trackingData }) => {
  const { sessionId, onFocus, onBlur } = useInputSearchTracking();
  const { t } = useTranslation('NS_GLOBAL_SEARCH');
  const Router = useRouter();
  const inputRef = useRef();
  const formRef = useRef();
  const typeBtnRef = useRef();
  const track = useNewTracking();
  const emptyFieldTimeoutRef = useRef();
  const { navigate } = usePageContext();

  const [isEmptySearchSubmitted, setIsEmptySearchSubmitted] = useState(false);
  const [isInputInFocus, setIsInputInFocus] = useState(false);
  const [inputValue, setInputValue] = useState(
    (Object.keys(INDEX_PAGE_SEARCH_TABS_MAPPING).includes(Router.pathname) && Router?.query?.query) || '',
  );
  const [typeaheadQuery, setTypeaheadQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [isTypeDropdownOpen, setIsTypeDropdownOpen] = useState(false);
  const [openVideosSearch, setOpenVideosSearch] = useState(false);
  const [openPerformanceSearch, setOpenPerformanceSearch] = useState(false);
  const searchHistory = useSearchHistory();
  const visitsHistory = useVisitsHistory();
  const searchQuery = Router.query?.query;

  const [activeSearchType, setActiveSearchType] = useState(searchQuery ? pathnameToFilterType(Router.pathname) : 'All');

  const isPerformanceOrVideoSearch = useMemo(
    () => [search_types.PRODUCTIONS, search_types.OPERA_ONLINE].includes(activeSearchType),
    [activeSearchType],
  );

  useEffect(() => {
    setActiveSearchType(searchQuery ? pathnameToFilterType(Router.pathname) : 'All');

    if (!searchQuery) {
      setInputValue('');
    }
  }, [Router.pathname, searchQuery]);

  const onFormSubmit = useCallback(
    event => {
      event.preventDefault();
      const data = new FormData(event.target);
      const searchParams = new URLSearchParams(data);
      const query = Object.fromEntries(searchParams.entries());
      if (!checkIfSearchQueryIsValid(query?.query)) {
        clearTimeout(emptyFieldTimeoutRef.current);
        setIsEmptySearchSubmitted(true);
        emptyFieldTimeoutRef.current = setTimeout(() => {
          setIsEmptySearchSubmitted(false);
        }, 10000);
        return;
      }
      pushSearchHistoryItem(query?.query, activeSearchType);
      inputRef.current.blur();
      const { type, subType } = getTypeAndSubTypeForOrganisations(activeSearchType);
      navigate.to(navigate.getLinkProps(getSectionLinkGenFn(type, subType)(query?.query)));
      setIsInputInFocus(false);
      track.click(
        {
          name: TRACK_EVENTS.CLICK,
          data: {
            id: GLOBAL_SEARCH_EVENT_IDS.GLOBAL_SEARCH_BUTTON,
            query: query?.query,
            tab: activeSearchType,
          },
        },
        GOOGLE_OLD_TRACKING_SERVICES,
      );
    },
    [navigate, track, activeSearchType],
  );

  useEffect(() => {
    clearTimeout(emptyFieldTimeoutRef.current);
  }, []);

  useEffect(() => {
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  // Handling navigating on result card click. onClick event seems to be cursed
  // TODO check if this is still needed
  useEffect(() => {
    const handleRouteChange = () => {
      setIsInputInFocus(false);
      setIsTypeDropdownOpen(false);
    };

    Router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      Router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [Router]);

  const debouncedOnQueryChange = useMemo(() => debounce(setTypeaheadQuery, 200), [setTypeaheadQuery]);

  useEffect(() => {
    const isValid = checkIfSearchQueryIsValid(inputValue);
    if (isEmptySearchSubmitted && isValid) {
      setIsEmptySearchSubmitted(false);
      clearTimeout(emptyFieldTimeoutRef.current);
    }
    debouncedOnQueryChange(isValid ? inputValue : '');

    if (isValid) {
      track.click(
        {
          name: TRACK_EVENTS.SEARCH,
          data: {
            id: GLOBAL_SEARCH_EVENT_IDS.GLOBAL_SEARCH_QUERY,
            query: inputValue,
          },
        },
        GOOGLE_OLD_TRACKING_SERVICES,
      );
    }
  }, [inputValue, debouncedOnQueryChange, isEmptySearchSubmitted, track]);

  useScrollBlocker((isInputInFocus && inputValue.length > 2 && typeaheadQuery.length > 2) || forceDrawerOpen);

  const onOptionClick = (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    if (type === 'productions') {
      setOpenPerformanceSearch(true);
      setInputValue('');
    } else if (type === 'videos') {
      setOpenVideosSearch(true);
      setInputValue('');
    } else if (inputValue?.trim()?.length > 0) {
      const { type: selectedType, subType } = getTypeAndSubTypeForOrganisations(activeSearchType);
      navigate.to(navigate.getLinkProps(getSectionLinkGenFn(selectedType, subType)(typeaheadQuery)));
    }
    setIsTypeDropdownOpen(false);
    setActiveSearchType(type);
    track.click(
      {
        name: TRACK_EVENTS.CLICK,
        data: {
          id: GLOBAL_SEARCH_EVENT_IDS.GLOBAL_SEARCH_ENTITY_TAB,
          query: typeaheadQuery,
          tab: type,
        },
      },
      GOOGLE_OLD_TRACKING_SERVICES,
    );
  };

  const onSearchInputBtnClicked = e => {
    e.preventDefault();
    e.stopPropagation();
    track.click(
      {
        name: TRACK_EVENTS.CLICK,
        data: {
          id: GLOBAL_SEARCH_EVENT_IDS.GLOBAL_SEARCH_QUERY_INPUT_CLICK,
        },
      },
      GOOGLE_OLD_TRACKING_SERVICES,
    );
    if (activeSearchType === 'productions') {
      setOpenPerformanceSearch(true);
    } else if (activeSearchType === 'videos') {
      setOpenVideosSearch(true);
    }
  };

  const onCloseNestedSearch = useCallback(() => {
    if (openVideosSearch) {
      setOpenVideosSearch(false);
    }
    if (openPerformanceSearch) {
      setOpenPerformanceSearch(false);
    }
  }, [openPerformanceSearch, openVideosSearch]);

  useEffect(() => {
    Router.events.on('routeChangeComplete', onCloseNestedSearch);
    return () => {
      Router.events.off('routeChangeComplete');
    };
  }, [Router, onCloseNestedSearch]);

  return (
    <div className={styles.searchContainer}>
      <ClickAwayListener
        onClickAway={() => {
          setIsInputInFocus(false);
          setIsTypeDropdownOpen(false);
        }}
      >
        <div ref={formRef}>
          <form
            action="/search"
            className={classnames(styles.searchForm, { [styles.searchFormError]: isEmptySearchSubmitted })}
            onSubmit={onFormSubmit}
            autoComplete="off"
          >
            <button
              type="button"
              className={styles.typeDropdownButton}
              onClick={() => {
                setIsTypeDropdownOpen(!isTypeDropdownOpen);
                setIsInputInFocus(false);
                inputRef.current.blur();
                track.click(
                  {
                    name: TRACK_EVENTS.CLICK,
                    data: {
                      id: GLOBAL_SEARCH_EVENT_IDS.GLOBAL_SEARCH_ENTITY_TAB_DROPDOWN,
                    },
                  },
                  GOOGLE_OLD_TRACKING_SERVICES,
                );
              }}
              ref={typeBtnRef}
            >
              <Typography size={12} weight="medium" className={styles.currentFilterName}>
                {t(section_label_map[activeSearchType])}
              </Typography>
              <SpriteIcon
                icon="keyboard_arrow_down"
                size={20}
                className={classnames(styles.iconBlack, { [styles.dropdownOpen]: isTypeDropdownOpen })}
              />
            </button>
            <Button
              variant="tertiary"
              className={styles.searchInputBtn}
              onClick={onSearchInputBtnClicked}
              trackingData={trackingData}
            >
              <input
                type="text"
                name="query"
                placeholder={getPlaceHolderText(t)}
                className={styles.queryInput}
                ref={inputRef}
                value={inputValue}
                onChange={ev => setInputValue(ev.target.value)}
                onFocus={e => {
                  e.stopPropagation();
                  setIsInputInFocus(true);
                  setIsTypeDropdownOpen(false);
                  onFocus();
                }}
                onBlur={onBlur}
                disabled={isPerformanceOrVideoSearch}
              />
            </Button>
            <SearchEndIcon
              inputValue={inputValue}
              isSearching={isSearching}
              isFocused={isInputInFocus}
              onClear={() => {
                inputRef.current.focus();
                setInputValue('');
                track.click(
                  {
                    name: TRACK_EVENTS.CLICK,
                    data: {
                      id: GLOBAL_SEARCH_EVENT_IDS.GLOBAL_SEARCH_CLEAR_BUTTON,
                      query: typeaheadQuery,
                      tab: activeSearchType,
                    },
                  },
                  GOOGLE_OLD_TRACKING_SERVICES,
                );
              }}
            />
          </form>
          <Popper
            anchorEl={formRef.current}
            open={isTypeDropdownOpen}
            className={classnames(styles.popper, styles.typeDropdownPopover)}
            disablePortal
            transition
            placement="top-start"
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={250}>
                <div className={classnames(styles.typeDropdownList, styles.popperContent)}>
                  {Object.keys(section_label_map).map(type => (
                    <LinkButton
                      disableUnderline
                      disableHover
                      key={type}
                      variant="text"
                      styles={{ root: styles.typeDropdownItem }}
                      onClick={e => onOptionClick(e, type)}
                      trackingData={{
                        ...trackingData,
                        subComponent: SUB_COMPONENTS.SEARCH_TAB_CTA,
                        meta: { tab: type, query: typeaheadQuery },
                      }}
                    >
                      <Typography
                        size={12}
                        color={activeSearchType === type ? 'link' : 'secondary'}
                        type={activeSearchType === type ? 'medium' : 'regular'}
                      >
                        {t(section_label_map[type])}
                      </Typography>
                    </LinkButton>
                  ))}
                </div>
              </Fade>
            )}
          </Popper>

          {/* Comparing both for better visual interaction, would be changed once "Recent" and "Trending" would be available */}
          <Popper
            open={
              (isInputInFocus &&
                ((inputValue.length > 2 && typeaheadQuery.length > 2) ||
                  searchHistory.length > 0 ||
                  visitsHistory.length > 0 ||
                  isEmptySearchSubmitted)) ||
              forceDrawerOpen
            }
            anchorEl={formRef.current}
            disablePortal
            transition
            className={styles.popper}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={250}>
                <div className={styles.popperContent}>
                  {isEmptySearchSubmitted && (
                    <div className={styles.errorMessage}>
                      <Typography size={12} color="inherit">
                        {t(`${TP}.SRCH_NEED_LETTERS`)}
                      </Typography>
                    </div>
                  )}
                  <TypeaheadSection
                    query={typeaheadQuery}
                    onSearchingChange={status => setIsSearching(status && typeaheadQuery.length > 2)}
                    searchType={activeSearchType}
                    searchHistory={searchHistory}
                    recentlyVisited={visitsHistory}
                    trackingData={{ ...trackingData, meta: { searchSessionId: sessionId } }}
                  />
                </div>
              </Fade>
            )}
          </Popper>
          <ClickAwayListener onClickAway={onCloseNestedSearch}>
            <Popper
              anchorEl={formRef.current}
              open={openPerformanceSearch || openVideosSearch}
              className={styles.nestedSearchPopper}
              disablePortal
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={250}>
                  <div className={classnames(styles.popperContent, styles.popContent)}>
                    {openPerformanceSearch && (
                      <PerformanceSearch
                        trackingData={{
                          ...trackingData,
                          component: COMPONENTS.ADVANCE_SEARCH,
                          category: CATEGORIES.SEARCH,
                          meta: { tab: activeSearchType, ...trackingData?.meta },
                        }}
                      />
                    )}
                    {openVideosSearch && (
                      <OperaOnlineSearch
                        trackingData={{
                          ...trackingData,
                          component: COMPONENTS.ADVANCE_SEARCH,
                          category: CATEGORIES.SEARCH,
                          meta: { tab: activeSearchType, ...trackingData?.meta },
                        }}
                      />
                    )}
                  </div>
                </Fade>
              )}
            </Popper>
          </ClickAwayListener>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export const SearchEndIcon = ({ isSearching, onClear, inputValue }) => {
  const { t } = useTranslation('NS_GLOBAL_SEARCH');
  if (isSearching) {
    return <CircularProgress size={12} className={styles.searchProgress} />;
  }
  return (
    <>
      {inputValue.length > 0 && (
        <button title="Reset" type="reset" className={styles.btnReset} onClick={onClear}>
          <Typography size={12} color="secondary" weight="regular">
            {t(`${TP}.FORM_CLEAR_ALL`)}
          </Typography>
        </button>
      )}
      <button title="Search" type="submit" className={styles.btnSearch}>
        <SpriteIcon icon="search" size={16} className={styles.searchIcon} />
      </button>
    </>
  );
};

export default HeaderSearch;
