/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import { TextButton } from 'components/uiLibrary/LinkButton';

import { SECTIONS, COMPONENTS, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { useTranslation } from 'src/i18n';

import { TP } from 'constants/index';

import NavLink from '../NavLink';

import classes from './HeaderLeftBlock.module.scss';

const HeaderLeftBlock = ({ onOpenMenu, onOpenProTools, trackingData, homePageLinkProps }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isDesktop } = useDeviceTypeLayouts();

  if (!isDesktop) {
    return (
      <ul className={classes.leftBlock}>
        <li className={classes.navItem}>
          <TextButton
            onClick={onOpenMenu}
            trackingData={{
              ...trackingData,
              subComponent: SUB_COMPONENTS.MENU,
            }}
            disableUnderline
            styles={{ root: classes.menu }}
            leftIcon={<SpriteIcon icon="menu" size="16" className={classes.menuIcon} />}
          >
            <Typography size="11">{t(`${TP}.MENU`)}</Typography>
          </TextButton>
        </li>
        <li className={classes.navItem}>
          <NavLink
            trackingData={{
              ...trackingData,
              subComponent: SUB_COMPONENTS.PRO_TOOLS_CTA,
            }}
            typographyProps={{ title: { size: 11, color: 'primary' } }}
            title={t(`${TP}.FN_PRO_TOOL`)}
            dropdown
            styles={{ icon: classes.icon }}
            onClick={onOpenProTools}
          />
        </li>
      </ul>
    );
  }

  return (
    <TextButton
      {...homePageLinkProps}
      disableUnderline
      isLink
      trackingData={{ section: SECTIONS.GLOBAL_HEADER, component: COMPONENTS.OPERABASE_BUTTON }}
    >
      <Typography size="13" color="secondary">
        {t(`${TP}.HEADER_MAIN_TEXT`)}
      </Typography>
    </TextButton>
  );
};

export default HeaderLeftBlock;
