/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment, useState } from 'react';
import classnames from 'classnames';

import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Popover from 'components/uiLibrary/Popover';
import EntityName from 'components/Globals/EntityName';
import Loader from 'components/uiLibrary/Loader';

import { COMPONENTS, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import { getComposerFromCreators } from 'utils/composer';
import usePageContext from 'utils/hooks/usePageContext';

import { useTranslation } from 'src/i18n';

import { TP, ENTITY_TYPE } from 'constants/index';
import { NAVIGATION_KEYS } from 'constants/header';
import { useQuery } from 'utils/react-query';

import queries from 'containers/Globals/queries';

import classes from './NavLink.module.scss';

const NavItem = ({ typographyProps, styles, isOpen, total, title, dropdown, formatter, isSelected = false }) => (
  <>
    <Typography
      color="secondary"
      size={13}
      {...typographyProps?.title}
      className={classnames(classes.item, { [classes.item__selected]: isSelected })}
    >
      {title}
      {dropdown && (
        <SpriteIcon
          icon={isOpen ? 'expand_less' : 'keyboard_arrow_down'}
          size="16"
          className={classnames(classes.icon, { [styles?.icon]: !!styles?.icon })}
        />
      )}
    </Typography>
    {total > 0 ? (
      <Typography size={11} color="tertiary" className={classes.count}>
        {formatter.format(total)}
      </Typography>
    ) : null}
  </>
);

const Entity = ({ type, isMenu, trackingData, ...entity }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  if (type === NAVIGATION_KEYS.CREATORS) {
    return (
      <Typography
        size="12"
        className={classnames(classes.entity, { [classes.entity__menu]: isMenu })}
        truncate
        color={isMenu ? 'secondary' : 'primary'}
      >
        <EntityName
          entity={entity}
          entityType={ENTITY_TYPE.ARTIST}
          isRaw
          trackingData={{
            ...trackingData,
            subComponent: null,
            entityId: entity?.id,
            entityName: entity?.name,
            entityType: ENTITY_TYPE.ARTIST,
          }}
        />
        {!isMenu && (
          <Typography size="11" color="secondary">
            {entity?.professions?.[0]?.profession?.name}
          </Typography>
        )}
      </Typography>
    );
  }

  if (type === NAVIGATION_KEYS.WORKS) {
    const composer = getComposerFromCreators(entity?.creators);

    return (
      <span className={classes.entity}>
        <Typography size="12" italic color="secondary" truncate>
          <EntityName
            entityType={ENTITY_TYPE.WORK}
            entity={entity}
            trackingData={{
              ...trackingData,
              subComponent: null,
              entityId: entity?.id,
              entityName: entity?.name,
              entityType: ENTITY_TYPE.WORK,
            }}
            typographyProps={{
              work: {
                size: 12,
                weight: 'regular',
                ...(isMenu && { color: 'secondary' }),
              },
              translation: {
                style: {
                  display: 'none',
                },
              },
            }}
          />
          ,{' '}
          <EntityName
            entity={{ id: composer?.profile?.id, name: composer?.profile?.shortName }}
            entityType={ENTITY_TYPE.ARTIST}
            isRaw
            trackingData={{
              ...trackingData,
              subComponent: null,
              entityId: composer?.profile?.id,
              entityName: composer?.profile?.shortName,
              entityType: ENTITY_TYPE.ARTIST,
            }}
          />
        </Typography>
        {!isMenu && (
          <span>
            {entity?.original_name?.toLowerCase() !== entity?.name?.toLowerCase() && (
              <Typography size="11" color="secondary" truncate>
                ({entity?.name}){' '}
              </Typography>
            )}
            <Typography size="11" color="secondary" textTransform="uppercase" truncate>
              {entity?.workTypes?.[0]?.name}
            </Typography>
          </span>
        )}
      </span>
    );
  }

  if (type === NAVIGATION_KEYS.WATCH_ONLINE) {
    const entityType = entity?.organizationType?.id ? ENTITY_TYPE.ORGANIZATION : ENTITY_TYPE.ARTIST;

    return (
      <Typography size="12" truncate color="secondary" className={classes.option__label}>
        <EntityName
          entity={entity}
          entityType={entityType}
          isRaw
          trackingData={{
            ...trackingData,
            subComponent: null,
            entityId: entity?.id,
            entityName: entity?.name,
            entityType,
          }}
        />
      </Typography>
    );
  }

  if (type === NAVIGATION_KEYS.SEASONS) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const startYear = currentYear - 1;
    const endYear = (startYear + 1)?.toString().slice(-2);

    return (
      <Typography
        size="12"
        {...(isMenu && { truncate: true })}
        color="secondary"
        className={classnames(classes.option__label, { [classes.option__seasonLabel]: !isMenu })}
      >
        <EntityName
          entity={entity}
          entityType={ENTITY_TYPE.ORGANIZATION}
          isRaw
          trackingData={{
            ...trackingData,
            entityId: entity?.id,
            entityName: entity?.name,
            entityType: ENTITY_TYPE.ORGANIZATION,
          }}
        />
        <Typography size="12" color="secondary" textTransform="lowercase">
          &nbsp;
          {startYear}/{endYear} {t(`${TP}.FN_SEASON`)}
        </Typography>
      </Typography>
    );
  }

  return (
    <Typography size="12" color="secondary" className={classes.option__label} truncate align="left">
      {entity?.name}
    </Typography>
  );
};

const ViewAll = ({ path, trackingData, total, title, isDrawer, onClose, type, ...rest }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  const renderViewAllText = () =>
    `${t(`${TP}.VIEW_ALL`)}${type !== NAVIGATION_KEYS.SEASONS ? ` ${total ? `${total} ` : ''}${title}` : ''}`;

  return (
    <LinkButton
      isLink
      variant="text"
      styles={{ root: classes.option }}
      href={path}
      onClick={onClose}
      {...rest}
      trackingData={{
        ...trackingData,
        subComponent: SUB_COMPONENTS.VIEW_ALL_CTA,
        meta: {
          id: path,
        },
      }}
    >
      <Typography size="12" truncate align="left" {...(isDrawer && { color: 'secondary' })}>
        {renderViewAllText()}
        <SpriteIcon icon="chevron_right" size="14" className={classes.option__rightIcon} />
      </Typography>
    </LinkButton>
  );
};

export const NavOptions = ({
  data,
  type,
  total,
  path,
  title,
  limit = 10,
  isMenu = false,
  isDrawer = false,
  linkProps = () => ({}),
  onClose,
  trackingData = {},
  dropdown,
  ...rest
}) => {
  const { navigate } = usePageContext();
  const formatter = Intl.NumberFormat();

  return (
    <>
      <Typography weight="bold" size={isDrawer ? 13 : 14}>
        {title}
      </Typography>
      <div
        className={classnames(classes.options, {
          [classes.options__menu]: isMenu,
          [classes.options__drawer]: isDrawer,
        })}
      >
        {isDrawer && (
          <ViewAll
            path={path}
            trackingData={trackingData}
            total={total}
            title={title}
            isDrawer={isDrawer}
            onClose={onClose}
            type={type}
            {...rest}
          />
        )}
        {data?.slice(0, limit).map(option => {
          const props = linkProps(option);
          const link = navigate.getLinkProps(props);
          return (
            <LinkButton
              key={option.label}
              styles={{ root: classes.option }}
              isLink
              variant="text"
              disableUnderline
              onClick={onClose}
              trackingData={{
                ...trackingData,
                subComponent: SUB_COMPONENTS.NAVIGATE_CTA,
                meta: {
                  id: link?.as,
                },
              }}
              {...link}
            >
              <Entity
                type={type}
                isMenu={isMenu}
                trackingData={{
                  ...trackingData,
                  component: COMPONENTS.HEADER_DROPDOWN,
                }}
                {...option}
              />
              {isDrawer ? (
                <span className={classes.option__totalSection}>
                  {option?.total ? (
                    <Typography size="12" color="secondary">
                      {option.total}
                    </Typography>
                  ) : (
                    <SpriteIcon icon="chevron_right" size="14" className={classes.rightIcon} />
                  )}
                </span>
              ) : (
                <span className={classes.option__totalSection}>
                  {option?.total > 0 ? (
                    <Typography size="12" color="secondary">
                      {formatter.format(option.total)}
                    </Typography>
                  ) : null}
                  <SpriteIcon icon="chevron_right" size="14" />
                </span>
              )}
            </LinkButton>
          );
        })}
        {(isMenu || dropdown) && (
          <ViewAll
            type={type}
            path={path}
            trackingData={trackingData}
            total={total}
            title={title}
            onClose={onClose}
            {...rest}
          />
        )}
      </div>
    </>
  );
};

const EntityOptions = props => {
  const { type } = props;

  const { data: entityItems, isFetching: isLoading } = useQuery(queries.getMenuItems({ type }));

  if (isLoading) {
    return <Loader className={classes.loader} />;
  }

  return <NavOptions {...props} data={entityItems?.data} />;
};

const NavLink = ({
  type,
  title,
  total,
  dropdown = false,
  typographyProps = { title: { size: 13 } },
  trackingData,
  styles,
  onClick,
  path,
  leftIcon,
  isSelected = false,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const formatter = Intl.NumberFormat();

  const handleMouseEnter = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const Wrapper = dropdown && !onClick ? 'div' : Fragment;

  return (
    <Wrapper {...(dropdown && !onClick && { onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave })}>
      <LinkButton
        styles={{ root: classnames(classes.navLink, { [classes.navLink__active]: open }) }}
        leftIcon={leftIcon}
        isLink={!onClick}
        {...(onClick && { onClick })}
        disableUnderline
        variant="text"
        href={path}
        {...rest}
        trackingData={{
          ...trackingData,
          meta: {
            id: path,
            title,
          },
        }}
      >
        <NavItem
          isOpen={open}
          total={total}
          title={title}
          dropdown={dropdown}
          typographyProps={typographyProps}
          styles={styles}
          formatter={formatter}
          isSelected={isSelected}
        />
      </LinkButton>
      {open && (
        <Popover
          open
          anchorEl={anchorEl}
          onClose={handleMouseLeave}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          className={classes.popover}
          classes={{
            paper: classes.popoverPaper,
          }}
          disableRestoreFocus
        >
          <EntityOptions
            type={type}
            total={total}
            path={path}
            title={title}
            onClose={() => setAnchorEl(null)}
            trackingData={trackingData}
            dropdown={dropdown}
            {...rest}
          />
        </Popover>
      )}
    </Wrapper>
  );
};

export default NavLink;
