import React from 'react';
import PropTypes from 'prop-types';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import ImageAvatar from 'components/uiLibrary/ImageAvatar';
import Typography from 'components/uiLibrary/Typography';
import Image, { TRANSFORMATIONS } from 'components/uiLibrary/Image';

import { getProfileName, getProfilePhotoURL } from 'utils/profileDropdown';
import { ENTITY_TYPE } from 'constants/index';
import usePageContext from 'utils/hooks/usePageContext';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';

import classes from './ProfileMenuButton.module.scss';

const UserEmail = ({ email, isDesktop }) => {
  const maxFirstPartLength = isDesktop ? 22 : 12;
  const [emailName, emailDomain] = email.split('@');

  if (emailName?.length > maxFirstPartLength) {
    return (
      <div className={classes.emailSection}>
        <Typography size="11" color={isDesktop ? 'secondary' : 'primary'}>
          {email}
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.emailSection}>
      <Typography size="11" color={isDesktop ? 'secondary' : 'primary'} truncate>
        {emailName}
      </Typography>
      <Typography size="11" color={isDesktop ? 'secondary' : 'primary'} truncate>
        @{emailDomain}
      </Typography>
    </div>
  );
};

const ProfileMenuButton = ({ profile, user, onClick }) => {
  const profileName = getProfileName(profile) || user?.name;
  const profilePhotoURL = getProfilePhotoURL(profile);
  const { entityType } = usePageContext();
  const { isDesktop } = useDeviceTypeLayouts();

  if (!isDesktop) {
    return (
      <div className={classes.profileMenuButton}>
        {user?.email && <UserEmail email={user.email} isDesktop={isDesktop} />}
        <SpriteIcon icon="keyboard_arrow_down" size="16" className={classes.profileMenuButton__arrowIcon} />
      </div>
    );
  }

  return (
    <div tabIndex={0} role="button" className={classes.profileMenuButton} onClick={onClick} onKeyDown={onClick}>
      {user?.email && <UserEmail email={user.email} />}
      {profilePhotoURL ? (
        <Image
          src={profilePhotoURL}
          alt={profileName}
          transformations={
            entityType === ENTITY_TYPE.ARTIST ? TRANSFORMATIONS.PROFILE_THUMBNAIL : TRANSFORMATIONS.AOS_LOGO
          }
          width={24}
          height={24}
          disableNextImage
        />
      ) : (
        <ImageAvatar
          name={profileName}
          className={classes.profileMenuButton__avatar}
          width={24}
          height={24}
          fontSize={12}
        />
      )}

      <SpriteIcon icon="keyboard_arrow_down" size="16" className={classes.profileMenuButton__arrowIcon} />
    </div>
  );
};

ProfileMenuButton.propTypes = {
  profile: PropTypes.object,
  onClick: PropTypes.func,
  user: PropTypes.object,
};

export default ProfileMenuButton;
