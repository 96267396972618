import { useRouter } from 'next/router';
import get from 'lodash/get';
import useTracking from 'components/Globals/Analytics';
import { enqueueSnackbar, SNACKBAR_VARIANTS } from 'components/uiLibrary/Snackbar';
import queries, { useCreateRegistrationMutation } from 'containers/Accounts/queries';
import { registrationPayload } from 'containers/Accounts/utils';
import { REGISTRATION_STEPS, REGISTRATION_COHORT_TYPES } from 'containers/Accounts/constants';
import { useUserData } from 'utils/hooks/useAuthenticatedUser';
import { useDialogs } from 'utils/hooks/useDialogs';
import { useQuery } from 'utils/react-query';
import usePageContext from 'utils/hooks/usePageContext';
import { usePaywallContext } from 'utils/hooks/usePaywallContext';
import { errorMessageConverter } from 'utils/common';
import { SUB_COMPONENTS, ACTION_CALLBACK_STEPS, ACTION_CALLBACK_STATUS } from 'components/Globals/Analytics/constants';
import { nextPageQueryName } from 'utils/auth';

import { STEP_VALUES, STEPS_LOGIN, BASE_PAGE_ROUTES } from 'constants/index';

const useClaimAccess = ({ onSuccess, trackingData } = {}) => {
  const Router = useRouter();
  const track = useTracking();
  const userData = useUserData();
  const { hidePaywall } = usePaywallContext();
  const { navigate } = usePageContext();
  const { setIsLoginDialog } = useDialogs();

  const { data: registrations } = useQuery(
    queries.getRegistrations({
      userId: userData?.id,
      queryConfig: { enabled: Boolean(userData?.id) },
    }),
  );

  const createRegistrationMutation = useCreateRegistrationMutation({
    onError: error => {
      enqueueSnackbar(errorMessageConverter(error), { variant: SNACKBAR_VARIANTS.ERROR });
      track.actionCallback({
        ...trackingData,
        meta: {
          step: ACTION_CALLBACK_STEPS.CREATE_REGISTRATION,
          actionStatus: ACTION_CALLBACK_STATUS.FAILURE,
          errorMessage: errorMessageConverter(error),
        },
      });
    },
    onSuccess: response => {
      navigate.to({
        href: {
          pathname: BASE_PAGE_ROUTES.REGISTER,
          query: { id: response.data.id, claim: true },
        },
      });
      track.actionCallback({
        ...trackingData,
        meta: {
          step: ACTION_CALLBACK_STEPS.CREATE_REGISTRATION,
          actionStatus: ACTION_CALLBACK_STATUS.SUCCESS,
        },
      });
    },
  });

  const enabledContinue =
    registrations?.data.length === 1 && registrations?.data.some(registration => !registration?.entities?.profile?.id);

  const claimNewProfileHandler = () => {
    if (enabledContinue) {
      setIsLoginDialog({ isOpen: true, step: STEP_VALUES[STEPS_LOGIN.REGISTRATION_UPDATE] });
    } else {
      setIsLoginDialog({ isOpen: true, step: STEP_VALUES[STEPS_LOGIN.CLAIM_NEW_PROFILE] });
    }

    // NOTE: Safely call onSuccess if it exists
    if (typeof onSuccess === 'function') {
      onSuccess();
    }
  };

  const createNewProfile = () => {
    hidePaywall();
    const url = { as: Router.asPath };
    const nextPage = url ? url.as : get(Router, `query.${nextPageQueryName}`);
    const payload = registrationPayload({
      userData,
      step: REGISTRATION_STEPS.createProfileExistingUser,
      origin: 'profile-drop-down',
      originLink: nextPage,
      flowId: REGISTRATION_COHORT_TYPES.INDUSTRY_PROFESSIONALS,
    });
    track.click({
      ...trackingData,
      subComponent: SUB_COMPONENTS.CLAIM_PROFILE,
    });
    createRegistrationMutation.mutate(payload);
  };

  return { claimNewProfileHandler, createNewProfile };
};

export default useClaimAccess;
