import React, { useState, useCallback, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { Router, useRouter } from 'next/router';

import { ADVANCED_SEARCH_TABS_NAMES, INDEX_PAGE_SEARCH_TABS_MAPPING } from 'constants/search';
import { useScrollBlocker } from 'utils/hooks/useScrollBlocker';

import SearchButton from './SearchButton';

const AdvancedSearchDrawer = dynamic(() => import('./AdvancedSearchDrawer'));

const AdvancedSearch = ({ onClose, trackingData = {} }) => {
  const [isDrawer, setIsDrawer] = useState(false);
  const router = useRouter();

  const [activeTab, setActiveTab] = useState('');

  useEffect(() => {
    let tab = ADVANCED_SEARCH_TABS_NAMES.ALL;
    // below condition is for manager country index page
    const isManagerPage = router.route.includes(ADVANCED_SEARCH_TABS_NAMES.MANAGER.toLowerCase());
    if (isManagerPage) {
      const regex = /\d/;
      const queryId = router?.query?.id?.split('-').pop();
      tab = queryId?.match(regex) ? tab : INDEX_PAGE_SEARCH_TABS_MAPPING[router?.route];
    } else {
      tab = INDEX_PAGE_SEARCH_TABS_MAPPING[router?.route] || tab;
    }
    setActiveTab(tab);
  }, [router]);

  useEffect(() => {
    // There are no scenarios where we would want to leave modal\drawer open when navigating to results.
    const closeDrawerOnRouteChange = () => {
      setIsDrawer(false);
    };
    Router.events.on('routeChangeComplete', closeDrawerOnRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', closeDrawerOnRouteChange);
    };
  }, []);

  useScrollBlocker(isDrawer);

  const openDrawer = useCallback(() => {
    setIsDrawer(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setIsDrawer(false);
    setActiveTab(activeTab);
    if (onClose) {
      onClose();
    }
  }, [onClose, activeTab]);

  useEffect(() => {
    Router.events.on('routeChangeComplete', closeDrawer);
    return Router.events.off('routeChangeComplete', closeDrawer);
  }, [closeDrawer]);

  return (
    <>
      <SearchButton onClickHandler={openDrawer} trackingData={trackingData} />
      {isDrawer && (
        <AdvancedSearchDrawer
          isOpen={isDrawer}
          onClose={closeDrawer}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          trackingData={trackingData}
        />
      )}
    </>
  );
};

export default AdvancedSearch;
