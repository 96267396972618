/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useCallback } from 'react';
import classnames from 'classnames';
import useOverlayScrollbars from 'utils/hooks/useOverlayScrollbars';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';

import classes from './HorizontalScroller.module.scss';

const DEFAULT_SCROLL_STEP = 200;
const SCROLL_ERROR_MARGIN = 1;

const HorizontalScroller = ({ styles, children, scrollStep = DEFAULT_SCROLL_STEP, outset = false }) => {
  const [scrollState, setScrollState] = useState({
    hasOverflowContent: false,
    scrollLeft: 0,
    scrollLength: 0,
  });
  const { hasOverflowContent, scrollLeft, scrollLength } = scrollState;

  const changeScrollArrowVisibility = useCallback(instance => {
    const state = instance.state();

    setScrollState({
      hasOverflowContent: state.hasOverflow.x || false,
      scrollLength: state.overflowAmount.x || 0,
      scrollLeft: instance.elements().viewport?.scrollLeft || 0,
    });
  }, []);

  const onScrollChangeHandler = useCallback(instance => {
    setScrollState(state => ({
      ...state,
      scrollLeft: instance.elements().viewport?.scrollLeft || 0,
    }));
  }, []);

  const { ref, getScrollbarInstance } = useOverlayScrollbars({
    options: {
      scrollbars: {
        visibility: 'hidden',
      },
    },
    events: {
      initialized: changeScrollArrowVisibility,
      updated: changeScrollArrowVisibility,
      scroll: onScrollChangeHandler,
    },
  });

  const changeViewportScrollPosition = useCallback(
    position => {
      const instance = getScrollbarInstance();
      if (instance) {
        const { viewport } = instance.elements();

        if (viewport) {
          viewport.scrollBy({
            left: position,
            behavior: 'smooth',
          });
        }
      }
    },
    [getScrollbarInstance],
  );

  const scrollLeftHandler = () => changeViewportScrollPosition(-scrollStep);
  const scrollRightHandler = () => changeViewportScrollPosition(scrollStep);

  return (
    <div
      className={classnames(classes.root, {
        [classes.scrollable]: hasOverflowContent,
        [classes.outset]: outset,
        [styles?.root]: !!styles?.root,
        [styles?.scrollable]: hasOverflowContent && !!styles?.scrollable,
      })}
    >
      {hasOverflowContent && scrollLeft > 0 && (
        <div
          className={classnames(classes.arrow, classes.arrow_left, { [styles?.arrow]: !!styles?.arrow })}
          onClick={scrollLeftHandler}
        >
          <SpriteIcon icon="chevron_left" />
        </div>
      )}
      <div ref={ref}>{children}</div>
      {hasOverflowContent && scrollLeft < scrollLength - SCROLL_ERROR_MARGIN && (
        <div
          className={classnames(classes.arrow, classes.arrow_right, { [styles?.arrow]: !!styles?.arrow })}
          onClick={scrollRightHandler}
        >
          <SpriteIcon icon="chevron_right" />
        </div>
      )}
    </div>
  );
};

export default HorizontalScroller;
