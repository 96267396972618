import { useMemo, useEffect } from 'react';
import { getCookie, setCookie } from 'utils/cookie';

import { AGENT_TYPE_ID, AOS_TYPE_ID } from 'constants/consts';
import { SUBSCRIPTIONS_SLUG } from 'containers/UserContainers/AccountSettingsContainer/constants';
import { getDaysUntilSubscriptionEnds } from 'utils/common';
import { useNotificationBanner } from 'utils/hooks/useNotificationBanner';
import { useSubscription } from 'utils/hooks/useSubscription';
import useGetTargetProfile from 'utils/hooks/useGetTargetProfile';
import useAppContext from 'utils/hooks/useAppContext';
import { ENTITY_TYPE } from 'constants/index';

export function useAnnualSubscriptionRenewalBanner() {
  const { entityType } = useGetTargetProfile();
  const { isRenewalBanner } = useNotificationBanner();
  const { isLoggedIn } = useAppContext();
  const subscription = useSubscription();
  const daysUntilSubscriptionEnds = getDaysUntilSubscriptionEnds(subscription);
  const profileType = entityType;
  const timeSetup = getCookie('setupTimeForRenewalBanner');
  useEffect(() => {
    if (daysUntilSubscriptionEnds <= 1) {
      setCookie('setupTimeForRenewalBanner', null);
    }
  }, [daysUntilSubscriptionEnds]);
  const activeShouldBeOpen = useMemo(
    () =>
      isLoggedIn &&
      (profileType === ENTITY_TYPE.ORGANIZATION ||
        (profileType === ENTITY_TYPE.AGENCY && subscription?.subscription?.slug !== SUBSCRIPTIONS_SLUG.AgencyFree)) &&
      subscription?.status === 'active' &&
      subscription?.paymentType === 'yearly' &&
      isRenewalBanner &&
      daysUntilSubscriptionEnds < 61 &&
      timeSetup !== 'true',

    [isLoggedIn, profileType, subscription, isRenewalBanner, daysUntilSubscriptionEnds, timeSetup],
  );

  const cancelledShouldBeOpen = useMemo(
    () =>
      isLoggedIn &&
      (profileType === AOS_TYPE_ID || profileType === AGENT_TYPE_ID) &&
      subscription?.status === 'cancelled' &&
      isRenewalBanner &&
      subscription?.paymentType === 'yearly',
    [isLoggedIn, profileType, subscription, isRenewalBanner],
  );

  return [activeShouldBeOpen, cancelledShouldBeOpen];
}
