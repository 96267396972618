import React, { useState } from 'react';
import { useRouter } from 'next/router';

import LanguageSelector from 'components/Globals/LanguageSelector';
import ProfileDropdown from 'components/Globals/ProfileDropdown';
import { SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import useAppContext from 'utils/hooks/useAppContext';
import { useDialogs } from 'utils/hooks/useDialogs';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import usePageContext from 'utils/hooks/usePageContext';
import { useTranslation } from 'src/i18n';

import { TP, ENTITY_TYPE } from 'constants/index';
import route from 'constants/routes';
import routesWithTitle from 'constants/routesWithTitle';

import NavLink from '../NavLink';
import UpdateProfile from '../UpdateProfile';

import classes from './HeaderRightBlock.module.scss';

const LoggedInView = ({ onOpenProTools, trackingData, isCastingPage, isRentalPage }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isDesktop } = useDeviceTypeLayouts();
  const { permissions } = usePageContext();

  const hasCastingToolAccess = permissions?.castingToolPermissions?.hasAccess;

  if (!isDesktop) {
    return (
      <ul className={classes.mobileRightBlockLoggedIn}>
        <li>
          <ProfileDropdown />
        </li>
      </ul>
    );
  }

  return (
    <nav>
      <ul className={classes.rightBlockLoggedIn}>
        <li className={classes.navItem}>
          <NavLink
            title={t(`${TP}.FN_PRO_TOOL`)}
            onClick={onOpenProTools}
            trackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.PRO_TOOLS_CTA }}
          />
        </li>
        <li className={classes.navItem}>
          <NavLink
            {...routesWithTitle.RENTALS}
            title={t(`${TP}.m_RENTALS`)}
            isSelected={isRentalPage}
            trackingData={{
              ...trackingData,
              subComponent: SUB_COMPONENTS.RENTAL_CTA,
            }}
          />
        </li>
        {!hasCastingToolAccess && (
          <li className={classes.navItem}>
            <NavLink
              {...routesWithTitle.CASTING_TOOL}
              title={t(`${TP}.FN_CASTINGTOOL`)}
              trackingData={{
                ...trackingData,
                subComponent: SUB_COMPONENTS.CASTING_TOOL,
              }}
            />
          </li>
        )}
        <li>
          <div className={classes.borderContainer}>
            {hasCastingToolAccess && (
              <div className={classes.navItem}>
                <NavLink
                  {...routesWithTitle.CASTING_TOOL}
                  title={t(`${TP}.FN_CASTINGTOOL`)}
                  trackingData={{
                    ...trackingData,
                    subComponent: SUB_COMPONENTS.CASTING_TOOL,
                  }}
                  isSelected={isCastingPage}
                  {...(isCastingPage && { typographyProps: { title: { color: 'link', weight: 'medium' } } })}
                />
              </div>
            )}
            <div className={classes.profileDropdownSection}>
              <ProfileDropdown />
            </div>
          </div>
        </li>
      </ul>
    </nav>
  );
};

const NonLoggedInView = ({ onOpenProTools, trackingData, isCastingPage, isRentalPage }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { setIsLoginDialog } = useDialogs();
  const { isDesktop } = useDeviceTypeLayouts();
  const [openUpdateProfileModal, setOpenUpdateProfileModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  if (!isDesktop) {
    return (
      <ul className={classes.rightBlock}>
        <li className={classes.loginText}>
          <NavLink
            title={`${t(`${TP}.m_LOGIN`)} / ${t(`${TP}.FN_AUTH_TYPE_REGISTRATION`)}`}
            onClick={() => setIsLoginDialog({ isOpen: true })}
            typographyProps={{ title: { size: 11, color: 'primary', weight: 'medium' } }}
            trackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.LOGIN_OR_REGISTER_CTA }}
          />
        </li>
      </ul>
    );
  }

  return (
    <>
      <nav>
        <ul className={classes.rightBlock}>
          <li className={classes.navItem}>
            <NavLink
              title={t(`${TP}.FN_PRO_TOOL`)}
              onClick={onOpenProTools}
              trackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.PRO_TOOLS_CTA }}
            />
          </li>
          <li className={classes.navItem}>
            <NavLink
              {...routesWithTitle.CASTING_TOOL}
              title={t(`${TP}.FN_CASTINGTOOL`)}
              trackingData={{
                ...trackingData,
                subComponent: SUB_COMPONENTS.CASTING_TOOL,
              }}
              isSelected={isCastingPage}
            />
          </li>
          <li className={classes.navItem}>
            <NavLink
              {...routesWithTitle.RENTALS}
              title={t(`${TP}.m_RENTALS`)}
              trackingData={{
                ...trackingData,
                subComponent: SUB_COMPONENTS.RENTAL_CTA,
              }}
              isSelected={isRentalPage}
            />
          </li>

          <li className={classes.navItem}>
            <NavLink
              title={t(`${TP}.UPDATE_PROFILE`)}
              onClick={e => {
                setOpenUpdateProfileModal(true);
                setAnchorEl(e.currentTarget);
              }}
              trackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.UPDATE_PROFILE_CTA }}
              isSelected={openUpdateProfileModal}
            />
          </li>
          <li className={classes.navItem}>
            <LanguageSelector type="header" />
          </li>
          <li className={classes.loginText}>
            <NavLink
              title={`${t(`${TP}.m_LOGIN`)} / ${t(`${TP}.FN_AUTH_TYPE_REGISTRATION`)}`}
              onClick={() => setIsLoginDialog({ isOpen: true })}
              trackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.LOGIN_OR_REGISTER_CTA }}
            />
          </li>
        </ul>
      </nav>
      {openUpdateProfileModal && (
        <UpdateProfile
          anchorEl={anchorEl}
          open={openUpdateProfileModal}
          onClose={() => {
            setOpenUpdateProfileModal(false);
          }}
        />
      )}
    </>
  );
};

const HeaderRightBlock = ({ onOpenProTools, trackingData }) => {
  const { isLoggedIn } = useAppContext();
  const { entityType } = usePageContext();
  const router = useRouter();
  const isCastingPage = router.route === route.CASTING_TOOL && entityType !== ENTITY_TYPE.ARTIST;
  const isRentalPage = router.route === '/rentals/[[...action]]';

  return isLoggedIn ? (
    <LoggedInView
      onOpenProTools={onOpenProTools}
      trackingData={trackingData}
      isCastingPage={isCastingPage}
      isRentalPage={isRentalPage}
    />
  ) : (
    <NonLoggedInView
      onOpenProTools={onOpenProTools}
      trackingData={trackingData}
      isCastingPage={isCastingPage}
      isRentalPage={isRentalPage}
    />
  );
};

export default HeaderRightBlock;
