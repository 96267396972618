import { useMemo } from 'react';

import { ENTITY_TYPE } from 'constants/index';

import { getDaysUntilSubscriptionEnds } from 'utils/common';
import useAppContext from 'utils/hooks/useAppContext';
import { useSubscription } from 'utils/hooks/useSubscription';
import { useUserData, useUserPaymentMethods } from 'utils/hooks/useAuthenticatedUser';
import useGetTargetProfile from 'utils/hooks/useGetTargetProfile';

export function useSubscriptionMissingPMBanner() {
  const { isLoggedIn } = useAppContext();
  const subscription = useSubscription();
  const daysUntilSubscriptionEnds = getDaysUntilSubscriptionEnds(subscription);
  const userPaymentMethods = useUserPaymentMethods();
  const userData = useUserData();
  const { entityType } = useGetTargetProfile();

  const hasNoMethod = useMemo(() => {
    if (!userPaymentMethods.loadedOnce) return false;
    if (userPaymentMethods.data.length === 0) return true;
    if (userPaymentMethods.data.find(method => method?.status === 'valid')) return false;
    return true;
  }, [userPaymentMethods]);

  const noPMBannerShouldBeOpen = useMemo(
    () =>
      isLoggedIn &&
      hasNoMethod &&
      entityType !== ENTITY_TYPE.ARTIST &&
      (subscription?.status === 'active' || subscription?.status === 'in_trial') &&
      subscription?.user?.id === userData?.id &&
      daysUntilSubscriptionEnds <= 9,
    [
      isLoggedIn,
      hasNoMethod,
      entityType,
      subscription?.status,
      subscription?.user?.id,
      userData?.id,
      daysUntilSubscriptionEnds,
    ],
  );

  return noPMBannerShouldBeOpen;
}
